import ToolInput from "components/commontools/InsertTool";
import ToolOutput from "components/commontools/ToolOutput";
import ToolsBanner from "components/commontools/toolsBanner";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import React, { useState, useRef, useEffect } from "react";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import ResetButton from "assets/resetbutton.jpg";
import CleanButton from "assets/cleanBtn.svg";
import ToolFaq from "components/QualityTools/ToolFaq";
import { QaToolFaqData } from "components/FAQ/config";
import { navigate } from "gatsby";
import useDebounce from "components/QualityTools/useDebounce";
import detectLanguage from "utils/detectLanguage";
import InsertToolsUrl from "components/commontools/InsertToolsUrl";
import QualityDifficultyOutput from "components/QualityTools/Qualitydifficult";
import { useLocation } from "@reach/router";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [inputText, setInputText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const refDiv = useRef(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const [urlText, setUrlText] = useState("");
  const debouncedInputText = useDebounce(inputText || urlText, 3000);
  const [isEnterText, setIsEnterText] = useState(true);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [tooltipContent, setTooltipContent] = useState("Copy");
  const [divHeight, setDivHeight] = useState(0);
  const [shareId, setShareId] = useState("");
  const location = useLocation();
  const url = location.href;

  useEffect(() => {
    if (refDiv.current) {
      setDivHeight(refDiv.current?.offsetHeight);
    }
  }, [refDiv]);

  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectLanguageForSource(debouncedInputText);
    }
  }, [debouncedInputText]);
  const detectLanguageForSource = async (text) => {
    const { languageCode } = await detectLanguage(text);
    if (languageCode) {
      setInputLang(languageCode);
    }
  };

  const handleClick = async () => {
    if (isButtonDisabled) return;

    setLoading(true);
    setError("");
    setShowOutput(false);
    let payload;
    if (urlText) {
      payload = {
        tool_name: "translation_difficulty_checker",
        user_url: urlText,
      };
    } else {
      payload = {
        tool_name: "translation_difficulty_checker",
        user_text: inputText,
        source_language_code: inputLang,
      };
    }

    try {
      const response = await http().post(endpoints.forms.aiTools, payload);
      setAnalysisResult(response);
     
      const newShareId = response?.meta?.share_id;
      setShareId(newShareId)
      setShowOutput(true);
      if (newShareId === undefined && !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }
      // navigate(
      //   `/tools/translation-difficulty-checker/result?share-id=${newShareId}`
      // );
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const isButtonDisabled = !urlText && !(inputText && inputLang);

  const handleReset = async () => {
    await handleClick();
  };
  const handleClearText = () => {
    setInputLang("");
    setInputText("");
    setWordCountInput(0);
    setAnalysisResult(null)
    setDivHeight(0)
    setUrlText("")
    setShowOutput(false)
  };
  const handleLinkChange = (e) => {
    const text = e.target.value;
    setUrlText(text);

    // setHasSummary(false);
  };
  const toggleInput = (isTextInput) => {
    setIsEnterText(isTextInput);
    setUrlText("");
    setInputText("");
    setAnalysisResult(null)
    setDivHeight(0)
    setUrlText("")
    setShowOutput(false)
  };
  const clearLink = () => {
    setUrlText("");
    setAnalysisResult(null)
    setDivHeight(0)
    setUrlText("")
    setShowOutput(false)
  };
  
  const shareresult = () => {
    navigator.clipboard.writeText(`${url}/result?share-id=${shareId}`);
    setTooltipContent("Link copied");

    setTimeout(() => {
      setTooltipContent("Copy");
    }, 2000);
  };

  return (
    <Layout>
      <SEO
        title="Translation Difficulty Checker"
        description="Evaluate your text's complexity with our translation difficulty checker to determine if you need machine translation, editing, or human translation.​"
        slug="/tools/translation-difficulty-checker"
      />
      <div className="bg-[#F5F5F5]">
        <div className="pt-5">
          <ToolsBreadcrumb />
        </div>
        <div className="max-w-7xl mx-auto px-4 py-[60px]">
          <ToolsBanner
            text="Translation Difficulty Checker"
            title="Identify the Best Translation Method for Your Text"
            description="Input text in any language, and the tool will analyze its complexity. You'll get a recommendation on whether to use raw machine translation, MTPE, or human translation. This makes it easier to choose the right translation method for your text, improving quality and efficiency."
          />
          <div className="lg:flex gap-3 mt-12">
            <div className="flex flex-col gap-3 w-full lg:w-[60%]">
              <div className="lg:flex items-center  rounded-lg shadow-lg ">
                <ToolInput
                  lang={inputLang}
                  text={inputText}
                  wordCount={wordCountInput}
                  setText={setInputText}
                  setInputLang={setInputLang}
                  setWordCount={setWordCountInput}
                  iscleanBtn={true}
                  isDifficulty={true}
                  refDiv={refDiv}
                  handleClearText={handleClearText}
                  placeholder="Type your text here or"
                  tooltype="pre"
                  urlText={urlText}
                  setUrlText={setUrlText}
                  clearLink={clearLink}
                  handleLinkChange={handleLinkChange}
                  loading={loading}
                  isEnterText={isEnterText}
                  divHeight={divHeight}
                  setAnalysisResult={setAnalysisResult}
                  result={analysisResult}
                  isResult = {true}
              
                >
                  {" "}
                  <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                    <div className=" text-[#6E6E6E] font-opensans font-bold">
                      <button
                        onClick={() => toggleInput(true)}
                        className={`py-1 px-3 rounded-md font-semibold ${
                          isEnterText
                            ? "text-white bg-[#7AA7FF]"
                            : "text-[#7AA7FF] bg-[#F0F5FF]"
                        }`}
                      >
                        Enter Text
                      </button>
                      <button
                        onClick={() => toggleInput(false)}
                        className={`py-1 px-3 rounded-md font-semibold ${
                          isEnterText
                            ? "text-[#7AA7FF] bg-[#F0F5FF]"
                            : "text-white bg-[#7AA7FF]"
                        }`}
                      >
                        Enter URL
                      </button>
                    </div>
                    {inputText && (
                      <img
                        src={CleanButton}
                        alt="clean-button"
                        onClick={handleClearText}
                        className="cursor-pointer  absolute right-[14px]"
                      />
                    )}
                    <div className="flex gap-3"></div>
                  </div>
                </ToolInput>
              </div>
            </div>

         
      
            <ToolOutput
              handleClick={handleClick}
              isButtonDisabled={isButtonDisabled}
              ResetButton={ResetButton}
              loading={loading}
              handleReset={handleReset}
              showOutput={showOutput}
              error={error}
              ref={refDiv}
              isDifficulty={true}
              isEnterText={isEnterText}
              result={analysisResult?.result}
              buttontext="Check Complexity"
              text={`Your results will appear here. <br/> Please enter your  ${
                isEnterText ? "texts" : "link"
              } and click "Check quality" to begin.`}
            >
              {/* {analysisResult && ( */}
              <QualityDifficultyOutput
                setDivHeight={setDivHeight}
                shareresult={shareresult}
                analysisResult={analysisResult?.result}
                refDiv={refDiv}
                isDifficulty={true}
                shareId={shareId}
                tooltipContent={tooltipContent}
                setTooltipContent={setTooltipContent}
              />
            {/* )} */}
            </ToolOutput>
          </div>
          <p className="w-full text-center text-base mt-12 mb-4 text-[#9C9C9C]">
            Assisted by AI tools. Inaccuracies may occur.
          </p>

          <p className="mt-8 md:mt-12 text-lg text-center font-opensans leading-[26px]">
            Input text in any language, and the tool will analyze its
            complexity. You'll get a recommendation on whether to use raw
            machine translation, MTPE, or human translation. This makes it
            easier to choose the right translation method for your text,
            improving quality and efficiency.
          </p>
        </div>
      </div>
      <div>
        <ToolFaq QaToolFaqData={QaToolFaqData} />
      </div>
    </Layout>
  );
};

export default Index;
