import Loader from "components/loader";
import React from "react";

const InsertToolsUrl = ({urlText,setUrlText,clearLink,handleLinkChange,loading}) => {
  return (
    <div>
      <textarea
        name="url"
        value={urlText}
        placeholder="Paste your link here"
        className={`min-h-[250px]  font-opensans placeholder-[#858585] text-[#1463FF] leading-[26px] py-3 px-3 bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full`}
        onChange={handleLinkChange}
        readOnly={loading}
      />
      <div className="flex justify-between items-center p-4 mt-8">
        <span></span>
        <div className="flex gap-2">
          <button
            type="button"
            className={` ${
              urlText.length > 0
                ? "text-[#0A2641] border border-[#0A2641] cursor-pointer"
                : "border border-[#B0B0B0] text-[#B0B0B0]"
            } flex gap-2 bg-white px-4 py-2 rounded-md`}
            onClick={clearLink}
            disabled={urlText.length === 0}
          >
            Clear link
          </button>
          {/* <button
            type="button"
            onClick={handleSubmit(onSubmit)}
            disabled={!urlText}
            className={`bg-[#5B93FF] ${
              !urlText ? " cursor-not-allowed" : ""
            } text-white px-4 py-2 rounded-md`}
          >
            {loading ? <Loader /> : "Summarize link"}
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default InsertToolsUrl;
